<template>
  <div id="item-id">
    <div v-if="layoutType == 'grid'">
      <div v-if="itemType == 'justFile'">
        <!-- <div class="dropdown is-right is-active">
          <div class="dropdown-trigger">
            <button
              class="button is-text"
              aria-haspopup="true"
              aria-controls="dropdown-menu6"
              
            >
              <span class="icon is-small">
                <i class="fas fa-ellipsis-v"></i>
              </span>
            </button>
          </div>
          <div class="dropdown-menu" id="dropdown-menu6" role="menu">
            <div class="dropdown-content">
              <div class="dropdown-content">
                <a href="#" class="dropdown-item"> Overview </a>
                <a href="#" class="dropdown-item"> Modifiers </a>
                <a href="#" class="dropdown-item"> Grid </a>
                <a href="#" class="dropdown-item"> Form </a>
                <a href="#" class="dropdown-item"> Elements </a>
                <a href="#" class="dropdown-item"> Components </a>
                <a href="#" class="dropdown-item"> Layout </a>
                <hr class="dropdown-divider" />
                <a href="#" class="dropdown-item"> More </a>
              </div>
            </div>
          </div>
        </div> -->
        <!-- <b-dropdown aria-role="list" position="is-bottom-left">
          <template #trigger="{ active }">
            <b-button icon-pack="fas" type="is-text" icon-right="ellipsis-v" />
          </template>

          <b-dropdown-item aria-role="listitem">Action</b-dropdown-item>
          <b-dropdown-item aria-role="listitem">Another action</b-dropdown-item>
          <b-dropdown-item aria-role="listitem">Something else</b-dropdown-item>
        </b-dropdown> -->
        <!-- {{ itemData }} -->
        <div class="card just-box-rounded">
          <div class="bar-contained-card">
            <!-- <div :class="setBarColor(itemData)"></div> -->

            <div class="" style="width: 100%">
              <div class="card-container-thin">
                <div class="media">
                  <div style="width: 100%">
                    <div class="columns">
                      <div class="column is-8">
                        <div class="media-content card-new-header">
                          <p v-if="showFullName" class="title is-6" @mouseleave="showFullName = false">{{ itemDisplayName
                          }}</p>
                          <p v-else class="title is-6" @mouseover="showFullName = true"
                            @mouseleave="showFullName = false">
                            {{
                              itemDisplayName
                              ? itemDisplayName
                              : itemName | truncate(16, "...")
                            }}
                          </p>
                          <p class="subtitle is-6">
                            <!-- <span
                              v-if="itemType == 'justFile'"
                              class="subtitle is-6"
                            >
                              ({{ itemid }})
                            </span> -->
                          </p>
                        </div>
                      </div>
                      <div class="column">
                        <div class="">
                          <div class="crtagcontainer">
                            <div v-if="itemData.report_only" class="above-report-tag">
                              <b-tooltip label="Report" type="is-dark" size="is-large">
                                <span class="report-tag">R</span>
                              </b-tooltip>
                            </div>
                            <!-- {{ itemData.activity_status }}{{ itemData.report_only }} -->

                            <div>
                              <span class="tag-custom tag-border-defult" :class="setTagColor(itemData)">{{
                                itemData.activity_status }}</span>
                            </div>
                            <!-- <div v-else class="on-end" style="padding-bottom: 10px; gap: 7px;">
                              <span :class="setDotColor(itemData)"></span>
                              <span :class="setBarColor(itemData)"></span>
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="content has-text-left">
                  <div class="card-content center">
                    <div>
                      <!-- <div>Pending On : {{ itemData.pending_on }}</div>
                      <div>Expire On : {{ localTime(itemData.expires) }}</div>
                      <div>Status : {{ itemData.state }}</div>
                      <div></div> -->
                      <div class="pending-on-container">
                        <span class="pending-on-container is-purple has-text-white">
                          Pending On :
                        </span>
                        <span class="name-pending-value">
                          {{ itemData.pending_on | truncate(10, "...") }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- <time datetime="2016-1-1">11:09 PM - 1 Jan 2016</time> -->
                  <div class="card-button-container">
                    <div>
                      <div class="barsign-container">
                        <span class="progress-half-left"> Pendency </span>
                        <span class="progress-half-right on-end">
                          <span>Urgency</span>
                        </span>
                      </div>
                      <div>
                        <span class="progress-half-left" :class="setDotColor(itemData)">
                        </span>
                        <span class="progress-half-right" :class="setBarColor(itemData)">
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="card-button-container">

                    <div id="navbarBasicExample" class="navbar-menu">
                      <div class="navbar-start">
                        <div class="navbar-item navbar-item-padding">
                          <button class="button button-new is-grey is-small is-light"
                            @click="selectItemWorkFlow(itemid, cardid)" @mouseover="showLabel = true"
                            @mouseleave="showLabel = false">
                            <span class="icon is-small">
                              <img src="../assets/icon_svg/plane.svg" />
                            </span>
                            <span v-if="showLabel" class="">Launch</span>

                          </button>
                        </div>
                        <div class="navbar-item navbar-item-padding">
                          <button class="button button-new is-small is-light" :disabled="itemReportOnly" @click="
                            onSelect(
                              $event,
                              itemid,
                              itemName,
                              itemType,
                              itemKey,
                              cardid
                            )
                            " @mouseover="showLabelSelect = true" @mouseleave="showLabelSelect = false">
                            <span class="icon is-small">
                              <img src="../assets/icon_svg/eye.svg" />
                            </span>
                            <span v-if="showLabelSelect" class="">Select</span>
                          </button>
                        </div>
                      </div>
                      <div class="navbar-end">
                        <div class="navbar-item navbar-item-padding">
                          <div>{{ localTime(itemData.expires) }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div @click="onClick($event, itemid, itemName, itemType, itemKey)">
          <div id="grit" class="center">
            <figure class="image is-96x96 item-container">
              <img v-if="itemType == 'fileClosed'" src="../assets/folderClosed.png" />
              <img v-else-if="itemType == 'fileOpen'" src="../assets/folderOpen.png" />
              <!-- <img v-else src="../assets/file.png" /> -->
            </figure>
          </div>

          <div id="disItem" class="center">
            <div>
              <div id="name" class="title is-6" style="margin-top: 10px; margin-bottom: 0px">
                {{ itemName }}
              </div>
              <span v-if="itemType == 'justFile'" class="subtitle is-6">
                ({{ itemid }})
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="layoutType == 'list'">
      <div class="list has-visible-pointer-controls">
        <div class="list-item">
          <div class="list-item-image">
            <figure class="image is-64x64">
              <img src="../assets/file.png" />
            </figure>
          </div>

          <div class="list-item-content">
            <div class="list-item-title">{{ itemName }}</div>
            <div class="list-item-description">{{ itemName }}</div>
          </div>

          <div class="list-item-controls">
            <div class="buttons is-right">
              <button @click="getItemHistroy(itemid)" class="button">
                <span class="icon is-small">
                  <i class="fas fa-clock-rotate-left"></i>
                </span>
                <span>History</span>
              </button>
              <button @click="selectItemWorkFlow(itemid)" class="button">
                <span class="icon is-small">
                  <i class="fas fa-edit"></i>
                </span>
                <span>{{ action }}</span>
              </button>

              <button class="button">
                <span class="icon is-small">
                  <i class="fas fa-ellipsis-h"></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <b-modal full-screen :active.sync="isSignModalActive" class="modal-close-always"> -->
    <!-- <signpopup></signpopup> -->
    <!-- </b-modal> -->
    <!-- <b-loading :is-full-page="false" v-model="operationIsLoading" ></b-loading> -->
    <!-- <OverlayPanel
      v-if="itemType != 'justFile'"
      ref="op"
      appendTo="disItem"
      :dismissable="true"
      style="width: 90rem"
    >
      <div>
        <template>
          <div class="card">
            <div class="card-content">
              <div class="content">
                <p class="control has-icons-left has-icons-right">
                  <input class="input" type="text" placeholder="Search" />
                  <span class="icon is-left">
                    <i class="fas fa-search" aria-hidden="true"></i>
                  </span>
                  <span class="icon is-small is-right">
                    <i class="fa-solid fa-pipe">{{ currentFolder }}</i>
                  </span>
                </p>

                <div class="grid-container">
                  <ffc
                    v-for="item in subFloderItem"
                    :itemName="item"
                    :itemType="item.itemType"
                    :itemKey="item.key"
                  ></ffc>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </OverlayPanel> -->
  </div>
</template>
<script>
import OverlayPanel from "primevue/overlaypanel";
import { async } from "q";
import { globalFunction } from "../services/global.function";
import { globalText } from "../resources/global.string.res";
import moment from "moment";
export default {
  props: {
    itemName: String,
    itemDisplayName: String,
    itemReportOnly: Boolean,
    itemid: String,
    itemType: {
      type: String,
    },
    itemKey: String,
    itemData: Object,
    layoutType: {
      type: String,
      default: "grid",
    },
    itemSetion: String,
    cardid: Number,
  },
  data() {
    return {
      delay: 700,
      clicks: 0,
      showLabel: false,
      showLabelSelect: false,
      showFullName: false,
      timer: null,
      txns: [],
      subFloderItem: [],
      currentFolder: "",
      isSignModalActive: false,
      action: globalText.action,
    };
  },
  components: {
    ffc: () => import("../components/folderComponent.vue"),
    signpopup: () => import("../components/signPopup.vue"),
    OverlayPanel,
  },
  computed: {
    itemFilesType() {
      console.log("file type", this.action, this.itemType);

      return this.itemType;
    },
    actualWorkFlowID() {
      return this.$store.getters.getWorkFlowID;
    },
    operationIsLoading: {
      get: function () {
        return this.$store.getters.isLoading;
      },
      // setter
      set: function (newValue) {
        this.$store.commit("isLoading", newValue);
      },
    },
  },
  methods: {
    onClick: async function (event, itemid, itemName, itemType, itemKey) {
      console.log(itemid);
      this.clicks++;
      if (this.clicks === 1) {
        var self = this;
        this.timer = setTimeout(function () {
          self.clicks = 0;
          // self.$root.$emit('getHistory',{"id":itemid,"name":itemName})
          console.log(self.isSignModalActive);
          if (itemType == "justFile" && !self.isSignModalActive) {
            self.getItemHistroy(itemid);
          }
        }, this.delay);
      } else {
        clearTimeout(this.timer);
        console.log("double click", itemType);
        if (itemType == "fileClosed") {
          this.getSubFolders(itemKey, itemName);
          // this.$refs.op.toggle(event);
        } else if (itemType == "justFile") {
          await this.selectItemWorkFlow(itemid);
          this.isSignModalActive = true;
        }
        this.clicks = 0;
      }
    },
    onSelect: async function (
      event,
      itemid,
      itemName,
      itemType,
      itemKey,
      cardid
    ) {
      this.$emit("selected", cardid);
      this.getItemHistroy(itemid, cardid);
      this.$root.$emit("select_card", cardid);
      this.getItemDetails(itemid, itemName);
    },
    getItemDetails: async function (itemid, itemName) {
      await this.$store.dispatch("FETCH_DETAILS", { itemid, itemName });
    },
    localTime(utcTime) {
      if (utcTime != "NA") {
        return moment
          .utc(utcTime, "DD MMM YYYY HH:mm:ss")
          .local()
          .calendar({ sameElse: "DD MMM YYYY HH:mm:ss" });
      } else {
        return "NA";
      }
    },
    selectItemWorkFlow: async function (value, cardid) {
      console.log("workflow value:", value, cardid);

      try {
        this.operationIsLoading = true;
        await this.getItemHistroy(value, cardid);
        if (value.includes('re-')) {
          let workflowid = value
          await this.$store.dispatch("FETCH_REPORT_DETAILS", { workflowid });
          let docType = 'report'
          this.$root.$emit("open_form", {cardid,docType});
          this.operationIsLoading = false;
        } else {


          await this.$store.dispatch("SELECT_WORKFLOW", { value });
          this.$emit("selected", cardid);
          let docType = 'normal'
          this.$root.$emit("open_form", {cardid,docType});

          this.operationIsLoading = false;
          if (this.actualWorkFlowID === value) {
            globalFunction.notify("is-success", "workflow selected successfully");
            this.txns = this.$store.getters.transactions;
            this.isSignModalActive = true;
            console.log("Transantion", this.txns);
          } else {
            console.log("workflow selection failed");
            globalFunction.notify(
              "is-danger",
              "could not fetch workflow details, please select another workflow"
            );
          }

        }
      } catch (error) {
        this.operationIsLoading = false;
        console.log("selectWorkflow error:", error);
      }
    },
    getSubFolders(itemKey, itemName) {
      console.log(itemKey);
      this.currentFolder = itemKey;
      console.log(itemKey, this.itemData);
      this.itemData.forEach((item) => {
        if (itemKey == item.name) this.subFloderItem = item.workflows;
      });
      console.log("Subfloder", this.subFloderItem);
      this.$store.dispatch("WORKFLOW_SUBFOLDER", {
        currentSeletedFloder: itemName,
        data: this.subFloderItem,
      });
    },
    getItemHistroy: async function (value, cardid) {
      await this.$store.dispatch("FETCH_HISTORY", { value, cardid });
    },
    setBarColor(itemData) {
      let classString = "";
      if (this.itemSetion == "pending") {
        if (itemData.timeout_status == "Not-Urgent") {
          classString = "cc-amber";
        } else if (itemData.timeout_status == "NA") {
          classString = "cc-green";
        } else {
          classString = "cc-red";
        }
      } else if (this.itemSetion == "archived") {
        if (itemData.timeout_status == "Not-Urgent") {
          classString = "cc-amber";
        } else if (itemData.timeout_status == "NA") {
          classString = "cc-green";
        } else {
          classString = "cc-red";
        }
      }
      return classString;
    },
    setDotColor(itemData) {
      let classString = "";
      if (this.itemSetion == "pending") {
        if (itemData.dormant_status == "Dormant") {
          classString = "cc-amber";
        } else if (itemData.activity_status == "Continuing") {
          classString = "cc-red";
        } else if (itemData.activity_status == "Completed") {
          classString = "cc-green";
        } else {
          classString = "cc-blue";
        }
      }
      if (this.itemSetion == "archived") {
        if (itemData.dormant_status == "Dormant") {
          classString = "cc-amber";
        } else if (itemData.activity_status == "Continuing") {
          classString = "cc-red";
        } else if (itemData.activity_status == "Completed") {
          classString = "cc-green";
        } else {
          classString = "cc-blue";
        }
      }

      return classString;
    },
    // name needs to change
    setTagColor(itemData) {
      let classString = "";
      if (itemData.activity_status == "Continuing") {
        classString = "tag-text-red";
      }
      if (itemData.activity_status == "Completed") {
        classString = "tag-text-green";
      }
      if (itemData.activity_status == "Finalized") {
        classString = "tag-text-bule";
      }

      return classString;
    },
  },
  beforeDestroy() { },
};
</script>
